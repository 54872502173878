/* eslint-disable no-underscore-dangle */

export default {
  on(ressenyes, handler, priority) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (typeof handler !== 'function') return self;
    const method = priority ? 'unshift' : 'push';
    ressenyes.split(' ').forEach((event) => {
      if (!self.ressenyesListeners[event]) self.ressenyesListeners[event] = [];
      self.ressenyesListeners[event][method](handler);
    });
    return self;
  },

  once(ressenyes, handler, priority) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (typeof handler !== 'function') return self;
    function onceHandler(...args) {
      self.off(ressenyes, onceHandler);
      if (onceHandler.__emitterProxy) {
        delete onceHandler.__emitterProxy;
      }
      handler.apply(self, args);
    }
    onceHandler.__emitterProxy = handler;
    return self.on(ressenyes, onceHandler, priority);
  },

  onAny(handler, priority) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (typeof handler !== 'function') return self;
    const method = priority ? 'unshift' : 'push';
    if (self.ressenyesAnyListeners.indexOf(handler) < 0) {
      self.ressenyesAnyListeners[method](handler);
    }
    return self;
  },

  offAny(handler) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (!self.ressenyesAnyListeners) return self;
    const index = self.ressenyesAnyListeners.indexOf(handler);
    if (index >= 0) {
      self.ressenyesAnyListeners.splice(index, 1);
    }
    return self;
  },

  off(ressenyes, handler) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (!self.ressenyesListeners) return self;
    ressenyes.split(' ').forEach((event) => {
      if (typeof handler === 'undefined') {
        self.ressenyesListeners[event] = [];
      } else if (self.ressenyesListeners[event]) {
        self.ressenyesListeners[event].forEach((eventHandler, index) => {
          if (
            eventHandler === handler ||
            (eventHandler.__emitterProxy && eventHandler.__emitterProxy === handler)
          ) {
            self.ressenyesListeners[event].splice(index, 1);
          }
        });
      }
    });
    return self;
  },

  emit(...args) {
    const self = this;
    if (!self.ressenyesListeners || self.destroyed) return self;
    if (!self.ressenyesListeners) return self;
    let ressenyes;
    let data;
    let context;
    if (typeof args[0] === 'string' || Array.isArray(args[0])) {
      ressenyes = args[0];
      data = args.slice(1, args.length);
      context = self;
    } else {
      ressenyes = args[0].ressenyes;
      data = args[0].data;
      context = args[0].context || self;
    }
    data.unshift(context);
    const ressenyesArray = Array.isArray(ressenyes) ? ressenyes : ressenyes.split(' ');

    ressenyesArray.forEach((event) => {
      if (self.ressenyesAnyListeners && self.ressenyesAnyListeners.length) {
        self.ressenyesAnyListeners.forEach((eventHandler) => {
          eventHandler.apply(context, [event, ...data]);
        });
      }
      if (self.ressenyesListeners && self.ressenyesListeners[event]) {
        self.ressenyesListeners[event].forEach((eventHandler) => {
          eventHandler.apply(context, data);
        });
      }
    });
    return self;
  },
};
